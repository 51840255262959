import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { getHeader } from '../api';
import Header from '../models/joined/Header';
import { useAuthContext } from './AuthProvider';

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  header: Header | null,
  lastUpdated: number,
  forceReload: () => Promise<void>,
  intervalCheck: () => void,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  header: null,
  lastUpdated: 0,
  forceReload: () => Promise.resolve(),
  intervalCheck: () => { },
};

export const HeaderContext = createContext<Context>(DEFAULTCONTEXT);

export const useHeaderContext = () => useContext(HeaderContext);

export default function HeaderProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);
  const { logged, lastUpdated } = useAuthContext();
  const valueRef = useRef<Context>();
  valueRef.current = value;

  useEffect(() => {
    if (0 === value.lastUpdated || lastUpdated > value.lastUpdated) {
      getHeader().then((header) => {
        setValue(_o => ({
          ..._o,
          loaded: true,
          header,
          lastUpdated: Date.now(),
          forceReload: async () => {
            const header = await getHeader();
            setValue(_o => ({ ..._o, loaded: true, header, lastUpdated: Date.now(), }));
          },
          intervalCheck: () => {
            if (valueRef.current && Date.now() - valueRef.current.lastUpdated > 5 * 60 * 1000) {
              valueRef.current.forceReload();
            }
          },
        }));
      }).catch(() => {
        setValue(_o => ({ ..._o, loaded: true, header: null, lastUpdated: Date.now(), forceReload: () => Promise.resolve() }));
      });
    }
  }, [lastUpdated, value.lastUpdated, value, logged]);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] HeaderProvider');
  return (
    <HeaderContext.Provider value={value}>
      {props.children}
    </HeaderContext.Provider>
  )
}
