import { memo, MouseEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import SvgButton from '../components/ui/SvgButton';
import { getArenaQna, getArenaResult, getCmsArenaQna, getMyArenaResult, putArenaQna } from '../utils/api';
// import ArenaUnit from '../utils/models/ArenaUnit';
import MyArenaResult from '../utils/models/joined/MyArenaResult';
import { useAppTemplateContext } from '../utils/providers/AppTemplateProvider';
import ArenaProvider, { ArenaContext } from '../utils/providers/ArenaProvider';
import { AuthContext } from '../utils/providers/AuthProvider';
import { BackgroundContext } from '../utils/providers/BackgroundProvider';
import { useHeaderContext } from '../utils/providers/HeaderProvider';
import ArenaChooserScrollbarVertical from './arena-chooser-scrollbar-vertical';
import './arena.scss';
import PageArenaIconGet from './arena/arena-icon-get';
import PageArenaIndexChooser from './arena/arena-index-chooser';
// import PageArenaIndexHeader from './arena/arena-index-header';
import ArenaMc from './arena/arena-mc';
import PageArenaScore from './arena/arena-score';
import PageArenaStageResult from './arena/arena-stage-result';
import PageArenaTeacherDownload from './arena/arena-teacher-download';
import './island.scss';

// export const IconMap = [2, 3, 4, 5, 6, 6, 7, 7, 8, 8, 8,];
export const IconMapGet = [1, 2, 3, 4, 5, 5, 6, 6, 7, 7, 8, 8,];
export const IconMap = [1, 2, 3, 4, 5, 6, 6, 7, 7, 8, 8, 8,];

// export const UNIT_TEXT = [
//   '',
//   '史前至夏商周',
//   '秦漢',
//   '三國兩晉南北朝',
//   '隋唐',
//   '宋元',
//   '明',
//   '',
//   '清 (清朝的統一、外力衝擊與內憂)',
//   '清（清廷的圖強）',
//   '', // 中華民國
//   '', // 中華人民共和國
//   '中華民國',
//   '中華民國',
//   '中華人民共和國',
//   '中華人民共和國',
// ];

export const getPassedUnitCount = (value: MyArenaResult) => Object.values(value.unitTopest).filter(x => x >= 16).length;

function PageArenaIndexChoosers() {
  const [loaded, setLoaded] = useState(false);
  // const [levelIndex, setLevelIndex] = useState(1);
  const { value } = useContext(ArenaContext);
  const { setSegment } = useContext(BackgroundContext);

  useEffect(() => {
    setSegment([120, 240]);
    // setLevelIndex(IconMap[getPassedUnitCount(value)]);
    setLoaded(true);
  }, [setSegment, value]);

  if (!loaded || 0 === value.arenas.length) {
    return <></>;
  }

  console.log('PageArenaIndexChoosers', value);

  return (
    <>
      {/* <PageArenaIndexHeader levelIndex={levelIndex} /> */}
      <div className="page-arena-index-chooser-wrapper">
        <ArenaChooserScrollbarVertical>
          <div className="page-arena-index-chooser">
            {value.arenas.map((_, i) =>
              <PageArenaIndexChooser key={_.id} index={_.id} />
            )}
          </div>
        </ArenaChooserScrollbarVertical>
      </div>
    </>
  );
}

interface PageArenaQnaProps {
  index: string,
  previewId?: string,
}

function PageArenaQna(props: PageArenaQnaProps) {
  const { index } = props;

  const { forceReload } = useHeaderContext();
  const { search } = useLocation();
  const [showIconGet, setShowIconGet] = useState(false);
  const { value, forceUpdate } = useContext(ArenaContext);
  const history = useHistory();

  const [qna, setQna] = useState<getArenaResult>({
    status: '',
    qqID: 0,
    question: {},
    questionDetails: {} as any,
    noOfQuestion: 0,
    correctedInSession: 0,
  });
  // const [selected, setSelected] = useState([false, false, false, false]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [showScore, setShowScore] = useState({
    // score: 0,
    totalCorrectAnswers: 0,
    show: false,
    showIconSetAfterwards: false,
  });
  // const [showDailyMax, setShowDailyMax] = useState(false);

  // const [randomOrder, setRandomOrder] = useState([1, 2, 3, 4].sort(() => .5 - Math.random()));

  useEffect(() => {
    console.log('arena', index);
    if (props.previewId) {
      let p = new URLSearchParams(search);

      getCmsArenaQna(parseInt(props.previewId), p.get('t') as string, p.get('h') as string).then(qna => {
        if (qna.qqID) {
          setQna(qna);
          setQuestionIndex(0);
        } else {
          alert('Arena QNA Preview Url Expired');
        }
      }).catch(() => {
        alert('Arena QNA Preview Url Error');
      });
    } else if (index !== undefined && undefined !== parseInt(index)) {
      getArenaQna(parseInt(index)).then((qna: any) => {
        // console.log(qna);
        if (qna.status) {
          // setShowDailyMax(true);
        } else {
          // setUnitId(units[parseInt(index) - 1].lv1id);

          setQna(qna);
          setQuestionIndex(qna.noOfQuestion);
          console.log('arena.tsx 38', qna);
        }
      });
    } else {
      let debugQnaType: 'mc' | 'mc-img' = 'mc';
      let qna = {
        status: '',
        qqID: 0,
        question: [],
        questionDetails: {
          id: 0,
          foreignID: 0,
          langID: 0,
          type: debugQnaType,
          img: '',
          name: 'loading...',
          option1: 'option1',
          option2: 'option2',
          option3: 'option3',
          option4: 'option4',
          option5: 'option5',
          option6: 'option6',
          sortShow: 4,
          correctAns: 1,
          updatedOn: '',
          updatedBy: '',
        },
        noOfQuestion: 1,
        correctedInSession: 0
      };
      setQna(qna);
      setQuestionIndex(qna.noOfQuestion);
      console.warn('qna.tsx warn', index);
    }
  }, [index, questionIndex, props.previewId, search]);

  const onBtnBackClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (showScore.show) {

    } else {
      if (window.confirm("你未完成問答離開，問答進度將不會被儲存！")) {

      } else {
        e.preventDefault();
      }
    }
  }
  // const onToggle = (index: number) => () => {
  //   setSelected(o => [0, 0, 0, 0].map((_, i) => index === i));
  // }

  const onSubmit = async (qId: number, ansIndex: string, cb: () => void = () => { }) => {
    // let ansIndex = randomOrder[selected.findIndex(x => x)];
    // let correct = qna.questionDetails.correctAns === ansIndex;
    // if (correct) {
    //   setSegment([240, 360]);
    // } else {
    //   setSegment([120, 240]);
    // }
    if (props.previewId) {
      return new Promise<boolean | void>((res, rej) => {
        setTimeout(() => {
          alert('Preview Mode. No update.');
          res(true);
        }, 1500);
      });
    }

    const result = await putArenaQna(qId, ansIndex);
    try {
      return new Promise<boolean | void>((res, rej) => {
        setTimeout(() => {
          // console.log(result, questionIndex);
          setTimeout(() => {
            cb();
            if (30 <= questionIndex) {
              // console.log(result, questionIndex);

              return getMyArenaResult().then(c => {
                setShowScore({ show: true, totalCorrectAnswers: result.totalCorrectAnswers, showIconSetAfterwards: getPassedUnitCount(value) !== getPassedUnitCount(c) });
                forceReload();
                forceUpdate(() => {
                });
                return;
              });
              // DONE
            } else {
              setQuestionIndex(o => o + 1);
              return true;
            }
          }, 100);
        }, 'development' === process.env.NODE_ENV ? 300 : 1500);
      });
    } catch (e) {
      return false;
    }
  }

  const onScoreClose = () => {
    // console.log(showScore.showIconSetAfterwards);
    if (showScore.showIconSetAfterwards) {
      setShowIconGet(true);
    } else {
      history.push('/arena/index');
    }
  }

  // if ('' === qna.status) {
  //   return <></>;
  // }

  return (
    <div className={`page-island-qna`}>
      {/* <QnaMatch qna={qna} questionIndex={questionIndex} onSubmit={onSubmit} /> */}
      {/* <QnaFill qna={qna} questionIndex={questionIndex} onSubmit={onSubmit} /> */}
      {/* <QnaSort qna={qna} questionIndex={questionIndex} onSubmit={onSubmit} /> */}
      {showIconGet ? <PageArenaIconGet /> :
        showScore.show
          ? <PageArenaScore index={index} totalCorrectAnswers={showScore.totalCorrectAnswers} questionTotal={30} onClick={() => onScoreClose()} />
          : 'mc' === qna.questionDetails.type
            ? <ArenaMc qna={qna} index={index} questionIndex={questionIndex} questionTotal={30} onSubmit={onSubmit} />
            : <ArenaMc extras={['img']} qna={qna} index={index} questionIndex={questionIndex} questionTotal={30} onSubmit={onSubmit} />
      }

      <Link className="btn-back" onClick={(e) => onBtnBackClick(e)} to={`/arena/index`}><SvgButton prefix="/assets/island/" src1="course1-button-back.svg" src2="course1-button-back-click.svg" alt="返回" /></Link>

      {props.previewId ? <div className="page-island-cms-preview-mode">
        <h4>CMS Preview Mode</h4>
        <li><button onClick={() => window.location.reload()}>Reload</button> to randomize answers orders </li>
        <li>Disabled submit to server</li>
        <pre>{JSON.stringify({ activate: qna.question.activate, type: qna.question.type }, null, 2)}</pre>
      </div> : null}
    </div>
  )
}

type PageArenaMode = undefined | 'index' | 'summary' | 'qna';

interface PageArenaProps {
  previewIndex?: string,
  previewId?: string,
}

function PageArena(props: PageArenaProps) {
  const history = useHistory();
  let { mode, index } = useParams<{ mode: PageArenaMode, index: string | undefined }>();
  const { json, setBg, setSegment } = useContext(BackgroundContext);
  const { logged } = useContext(AuthContext);
  const { version, setVersion } = useAppTemplateContext();

  useEffect(() => {
    setVersion("App");
    setBg('Arena');
    switch (mode) {
      case 'index': setSegment([120, 240]); break;
      case 'summary': setSegment([120, 240]); break;
      case 'qna': setSegment([120, 240]); break;
      default: setSegment([0, 120]); break;
    }
  }, [setVersion, setBg, mode, index, setSegment]);

  if ('AppV4' === version || null === json) {
    return <></>;
  }

  // useEffect(() => {
  //   // -BACKGROUND ANIMATION
  //   // 1-4秒係COVER 進入面; 4-8秒(回答問題中 ); 8-12秒(失敗); 12-16秒(成功);
  //   console.log(mode);
  //   switch (mode) {
  //     case 'index': setSegment([120, 240]); break;
  //     case 'qna': setSegment([120, 240]); break;
  //     default: setSegment([0, 120]); break;
  //   }
  // }, [mode, setSegment]);

  // const [selected, setSelected] = useState(index ?? 0);
  // const onSelect = (s: number) => {
  //   setSelected(s);
  // }
  // console.log(mode);
  return (
    <ArenaProvider>
      <div className={`page-island page-arena page-arena-${index}`}>
        {undefined === mode
          ? <div className="page-arena-landing">
            <button onClick={() => history.push('/arena/index')}>
              <SvgButton prefix="/assets/arena/battle-button-" src1="start.svg" src2="start-select.svg" alt=""></SvgButton>
            </button>
          </div>
          : <div>{'index' === mode
            ? <>
              {logged && 'Teacher' === logged.role ? <PageArenaTeacherDownload /> : null}

              <div className="page-arena-index">
                <PageArenaIndexChoosers />
              </div>
            </>
            : <div style={{ width: '90%', left: '5%' }}>
              {'summary' === mode
                ? <PageArenaStageResult index={index as string} onClick={() => history.push(`/arena/qna/${index as string}`)} />
                : <PageArenaQna index={index as string} previewId={props.previewId} />
              }
            </div>
          }
          </div>
        }

        {'qna' === mode
          ? null
          : <Link className="btn-back btn-back-homepage" aria-label="回到主頁" to={`/`}><SvgButton prefix="/assets/island/" src1="course1-button-back-homepage.svg" src2="course1-button-back-homepage-mouseover.svg" alt="回到主頁" /></Link>
        }
      </div>
    </ArenaProvider>
  )
}

export default memo(PageArena);
