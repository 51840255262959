import ArenaQuestionDetail from './models/ArenaQuestionDetail';
import HistoryResource from './models/HistoryResource';
import HistoryResourceCategory from './models/HistoryResourceCategory';
import Header from './models/joined/Header';
import MyArenaResult from './models/joined/MyArenaResult';
import MyTreasureResult from './models/joined/MyTreasureResult';
import MyUnitDetail from './models/joined/MyUnitDetail';
import MyUnitResult from './models/joined/MyUnitResult';
import LeaderboardUser from './models/LeaderboardUser';
import News from './models/News';
import NoticeBoard from './models/NoticeBoard';
import QnaQDetail from './models/QnaQDetail';
import Unit from './models/Unit';
import User from './models/User';

const BASE_URL = window.location.host === 'hohoho1018.ddnsfree.com' || (window.location.host === 'localhost:3000' && 'development' === process.env.NODE_ENV)
  ? 'https://hohoho1018.ddnsfree.com'
  : window.location.host === 'www3.chist-elearning.org' || window.location.host === 'www.chist-elearning.org'
    ? `https://${window.location.host}`
    : window.location.host === 'www02.e-chist.com' || window.location.host === 'www01.e-chist.com' || window.location.host === 'dev.e-chist.com' || window.location.host === 'wwwuat.e-chist.com'
      ? `https://${window.location.host}`
      : 'https://www.e-chist.com';

console.log('BASE_URL:', BASE_URL);

export const LOGIN_URL = (window.location.host === 'localhost:3000' && 'development' === process.env.NODE_ENV)
  ? 'https://chist.pho.dev.eastern-color.com/api-v2/dev-auth/sign-in/'
  : '/edconnect/login.php'

export const LOGOUT_URL = window.location.host === 'chist.pho.dev.eastern-color.com'
  || (window.location.host === 'localhost:3000' && 'development' === process.env.NODE_ENV)
  || window.location.host === 'www3.chist-elearning.org'
  || window.location.host === 'dev.e-chist.com'
  ? 'https://developers.hkedcity.net/edconnect/oauth2/logout.php'
  : 'https://edconnect.hkedcity.net/authen/logout.php'

export function jsonFetch<T = any | Array<any>>(url: string, body: { [k: string]: any } | null = null, method: 'GET' | 'POST' | 'PUT' = 'GET'): Promise<T> {
  return fetch(`${BASE_URL}${url}`, {
    method: method,
    headers: {
      'content-type': 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
    // crossDomain: true,
    // xhrFields: { withCredentials: true },
    body: body ? JSON.stringify(body) : null,
  }).then(r => r.json());
}
export function authHeartbeat() {
  return jsonFetch<User>(`/api-v2/auth/heartbeat`);
}
export function authSignOut() {
  return jsonFetch(`/api-v2/sign-out`);
}
export function getTrivia(unitId: number) {
  return jsonFetch(`/api-v2/trivia/${unitId}`);
}
export function postGameResult(unitId: number, score: number, status: 'completed' | 'exit' = 'exit', type: 'video' = 'video') {
  return jsonFetch(`/api-v2/user/game-result`, { unitId, score, type, status }, 'POST');
}
export function putUserSettings(name: string, value: number) {
  return jsonFetch(`/api-v2/user/user-settings`, { name, value }, 'PUT');
}
export function getRanking(type: 'all' | 'school') {
  return jsonFetch<Array<LeaderboardUser>>(`/api-v2/user/ranking?type=${type}`);
}
export function getUnits() {
  return jsonFetch<Array<Unit>>(`/api-v2/user/unit`);
}
export function getHeader() {
  return jsonFetch<Header>(`/api-v2/user/header`);
}
export function getNews() {
  return jsonFetch<Array<News>>(`/api-v2/news`);
}
export function getNoticeBoard() {
  return jsonFetch<Array<NoticeBoard>>(`/api-v2/noticeBoard`);
}
export interface HistoryResourcesRecord {
  categories: Array<HistoryResourceCategory>,
  resources: Array<HistoryResource>,
  map: Array<{ id: string, historyResource_id: string, historyResourceCateyory_id: string }>,
  userFavourites: Array<{ historyResource_id: string }>,
}
export function getHistoryResources() {
  return jsonFetch<HistoryResourcesRecord>(`/api-v2/user/historyResources`);
}
export function postHistoryResources(rId: string, isAdd: boolean) {
  return jsonFetch<HistoryResourcesRecord>(`/api-v2/user/historyResources`, { rId, isAdd }, 'POST');
}
export function getAboutUs() {
  return jsonFetch<{ content: string }>(`/api-v2/about-us`);
}
export interface getQnaResult {
  status: string,
  qqID: number,
  question: any,
  questionDetails: QnaQDetail,
  noOfQuestion: number
}
export interface getArenaResult {
  status: string,
  qqID: number,
  question: any,
  questionDetails: ArenaQuestionDetail,
  noOfQuestion: number,
  correctedInSession: number,
}
export function getQna(unitId: number) {
  return jsonFetch<getQnaResult>(`/api-v2/user/qna/${unitId}`);
}
export function getPlayLogStart(unitId: number) {
  return jsonFetch(`/api-v2/user/play-log/start/${unitId}`);
}
export function putQna(qId: number, ans: string) {
  return jsonFetch<{ action: string, status: string, totalScore: number, totalCorrectAnswers: number }>(`/api-v2/user/qna`, { qId, ans }, 'PUT');
}
export function getMyUnitDetails(unitId: number) {
  return jsonFetch<Array<MyUnitDetail>>(`/api-v2/user/my-unit-details/${unitId}`);
}
export function getMyUnitsResult() {
  return jsonFetch<Array<MyUnitResult>>(`/api-v2/user/my-units-result`);
}
export function getUserRankingScore() {
  return jsonFetch<Array<{ sum_score: string }>>(`/api-v2/user/user-ranking-score`);
}
export function postLoginLegacy(param: { part1: string, part2: string, password?: string }) {
  return jsonFetch<User>(`/api-v2/legacy-sign-in`, param, 'POST');
}
export function postEnquiry(param: { email: string, category: string, details: string }) {
  return jsonFetch<{ action: string, emailSent: boolean }>(`/api-v2/user/enquiry/`, param, 'POST');
}

export function getTreasureMostValue() {
  return jsonFetch<Array<{ name: string, value: number }>>(`/api-v2/user/treasure-most-value`);
}
export function getTreasureCount() {
  return jsonFetch<Array<{ name: string, value: number }>>(`/api-v2/user/treasure-count`);
}
export function getMyTreasureResult() {
  return jsonFetch<MyTreasureResult>(`/api-v2/user/my-treasure-result`);
}
export function postTreasurePurchase(param: { treasureID: number }) {
  return jsonFetch<{ status: '' | 'sold_out' | 'purchased' | 'insufficient' | 'succeed' | 'unavailable' | 'max_purchases_per_student' }>(`/api-v2/user/treasure-purchase`, param, 'POST');
}
export function postTreasureCollect(param: { treasureID: number, area: 'star_islands' | 'islands' }) {
  return jsonFetch<{ status: '' | 'purchased' | 'insufficient' | 'unavailable', wrong: boolean }>(`/api-v2/user/treasure-collect`, param, 'POST');
}
export function putTreasureAnswerRead(param: {}) {
  return jsonFetch<{}>(`/api-v2/user/treasure-answer/read`, param, 'PUT');
}
export function postTreasureAnswer(param: { treasureQuestionID: number, aID: number }) {
  return jsonFetch<{ status: '' | 'updated' | 'exists' }>(`/api-v2/user/treasure-answer`, param, 'POST');
}
export function getMyArenaResult() {
  return jsonFetch<MyArenaResult>(`/api-v2/user/my-arena-result`);
}
export function getArenaQna(index: number) {
  return jsonFetch<getArenaResult>(`/api-v2/user/arena-qna/${index}`);
}
export function putArenaQna(qId: number, ans: string) {
  return jsonFetch<{ action: string, status: string, totalScore: number, totalCorrectAnswers: number }>(`/api-v2/user/arena-qna`, { qId, ans }, 'PUT');
}

export function getTeacherReportUrl() {
  return `${BASE_URL}/api/getReport.php`;
}
export function getArenaTeacherReportUrl() {
  return `${BASE_URL}/api/getReport_Arena.php`;
}
export function getV4ReadingHistoryTeacherReportUrl() {
  return `${BASE_URL}/api/getReport_V4ReadingHistory.php`;
}
export function getV4ReadingHistoryDownloadFilter() {
  return jsonFetch<{
    // arenaUnits: Array<{ id: number, name: string }>,
    levelsClasses: Array<{ level: string, class: string }>,
  }>(`/api-v2/user/v4-reading/teacher-download-filter`);
}
export function getArenaTeacherDownloadFilter() {
  return jsonFetch<{
    arenaUnits: Array<{ id: number, name: string }>,
    levelsClasses: Array<{ level: string, class: string }>,
  }>(`/api-v2/user/arena-teacher-download-filter`);
}

//
export function getCmsQna(qnaId: number, t: string, h: string) {
  return jsonFetch<getQnaResult>(`/api-v2/cms/qna/${qnaId}/${t}/${h}`);
}
export function getCmsArenaQna(arenaQnaId: number, t: string, h: string) {
  return jsonFetch<getArenaResult>(`/api-v2/cms/arena-qna/${arenaQnaId}/${t}/${h}`);
}
