import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { getNews } from '../api';
import News from '../models/News';
import { AuthContext } from './AuthProvider';

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  news: Array<News>,
  lastUpdated: number,
  forceReload: () => Promise<void>,
  intervalCheck: () => void,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  news: [],
  lastUpdated: 0,
  forceReload: () => Promise.resolve(),
  intervalCheck: () => { },
};

export const NewsContext = createContext<Context>(DEFAULTCONTEXT);

export const useNewsContext = () => useContext(NewsContext);

export default function NewsProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);
  const { logged, lastUpdated } = useContext(AuthContext);
  const valueRef = useRef<Context>();
  valueRef.current = value;

  useEffect(() => {
    if (lastUpdated > value.lastUpdated) {
      getNews().then((news) => {
        setValue(_o => ({
          loaded: true,
          news,
          lastUpdated: Date.now(),
          forceReload: async () => {
            const news = await getNews();
            setValue(_o => ({ ..._o, loaded: true, news, lastUpdated: Date.now(), }));
          },
          intervalCheck: () => {
            if (valueRef.current && Date.now() - valueRef.current.lastUpdated > 60 * 60 * 1000) {
              _o.forceReload();
            }
          },
        }));
      }).catch(() => {
        setValue(_o => ({ ..._o, loaded: true, news: [], lastUpdated: Date.now(), forceReload: () => Promise.resolve() }));
      });
    }
  }, [lastUpdated, value.lastUpdated, value, logged]);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] NewsProvider');
  return (
    <NewsContext.Provider value={value}>
      {props.children}
    </NewsContext.Provider>
  )
}
