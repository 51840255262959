import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  json: string | null,
  imgFolder: string | null,
  segment?: Array<number>,
  setBg: (json: null | string, imgFolder?: string | null) => void,
  setSegment: (segment: Array<number>) => void,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  json: 'Index',
  imgFolder: 'Index',
  setBg: (_json: null | string, _imgFolder?: string | null, _segment?: Array<number>) => { },
  setSegment: (_segment: Array<number>) => { },
};

export const BackgroundContext = createContext<Context>(DEFAULTCONTEXT);

export const useBackgroundContext = () => useContext(BackgroundContext);

export default function BackgroundProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);
  const valueRef = useRef(value);

  useEffect(() => {
    setValue(o => ({
      ...o,
      loaded: true,
      setBg: (newJson: null | string, newImgFolder?: string | null) => {
        ('development' === process.env.NODE_ENV) && console.log('setBg', newJson, newImgFolder);
        if (newJson !== valueRef.current.json) {
          setValue(o => ({ ...o, segment: undefined, json: newJson, imgFolder: newImgFolder ?? newJson }))
        }
      },
      setSegment: (segment: Array<number>) => setValue(o => ({ ...o, segment })),
    }))
  }, []);

  if (false === value.loaded) {
    return <></>;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] BackgroundProvider', value);
  return (
    <BackgroundContext.Provider value={value}>
      {props.children}
    </BackgroundContext.Provider>
  )
}
