import { useContext, useRef } from 'react';
import Lottie from 'react-lottie';
import { requireAnimationData } from './BaseIcon';
import { BackgroundContext } from '../../utils/providers/BackgroundProvider';
// import { CSSTransition, TransitionGroup } from "react-transition-group";
// import { useLocation } from 'react-router-dom';

export default function Background() {
  const { json, imgFolder, segment } = useContext(BackgroundContext);
  const lottieDiv = useRef(null);
  // let location = useLocation();
// console.log('Background', json);
  if (null === json) {
    return <></>;
  }

  const animationData = requireAnimationData(json, imgFolder ?? json);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (segment && null !== lottieDiv.current) {
    // console.log('segment', segment);
    // @ts-ignore
    (lottieDiv.current as LottieDiv).anim.playSegments(segment, true);
  }
// console.log('1234');
  return (
    // <TransitionGroup className="tg-bg">
    //   <CSSTransition key={location.key} classNames="fade" timeout={300}>

        <Lottie ariaRole="background" options={defaultOptions} ref={(e: any) => {
          lottieDiv.current = e;

          if ((lottieDiv.current as any)) {
            // console.log('frameRate', lottieDiv.current, (lottieDiv.current as any).frameRate);
            let anim: any = (lottieDiv.current as any).anim;
            anim.setSubframe(false);

            ((lottieDiv.current as any).options.container as HTMLDivElement).tabIndex = -1;
          }
        }} />
    //   </CSSTransition>
    // </TransitionGroup>
  );
}
