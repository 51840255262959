import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode,
}

interface Context {
  loaded: boolean,
  logs: Array<any>;
  show: boolean,
  log: (_log: any) => void;
  setShow: (_show: (_show: boolean) => boolean) => void,
}

const DEFAULTCONTEXT: Context = {
  loaded: false,
  logs: [],
  show: false,
  log: (_log: any) => { },
  setShow: (_show: (_show: boolean) => boolean) => { },
};

export const DevPortalContext = createContext<Context>(DEFAULTCONTEXT);

export const useDevPortalContext = () => useContext(DevPortalContext);

interface DevPortalProps {
  children: ReactNode,
}
export const DevPortal = ({ children }: DevPortalProps) => {
  return document.getElementById('__dev') && createPortal(children, document.getElementById('__dev') as HTMLDivElement);
}

export default function DevPortalProvider(props: Props) {
  const [value, setValue] = useState<Context>(DEFAULTCONTEXT);

  useEffect(() => {
    setValue(o => ({
      ...o,
      loaded: true,
      log: (log: string) => setValue(o => ({ ...o, logs: [log, ...o.logs] })),
      setShow: (show: (_show: boolean) => boolean) => setValue(o => ({ ...o, show: show(o.show) })),
    }))
    return () => {
    }
  }, []);

  if (!value.loaded) {
    return null;
  }

  ('development' === process.env.NODE_ENV) && console.log('[b4-return] DevPortalProvider');
  return (
    <DevPortalContext.Provider value={{ ...value }}>
      {props.children}
    </DevPortalContext.Provider>
  )
}

export const DevPortalDiv = () => {
  const { logs, show, setShow } = useDevPortalContext();
  // const [show, setShow] = useState(false);

  if ("development" === process.env.NODE_ENV) {
    return createPortal(<div style={{
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: 'rgba(0, 64, 0, .5)',
      color: 'white',
      zoom: 1,
      // pointerEvents: 'none',
      zIndex: 5000,
    }}>
      <div id="__dev" style={{
        display: show ? 'block' : 'none',
      }}>
        <div style={{ height: '10vh', width: '10vw', pointerEvents: 'none', overflowY: 'scroll' }}>{logs.map((l, i) => <p key={i}>{JSON.stringify(l)}</p>)}</div>

        <div className="dev-css-mq">
          <div className="dev-css-mq-16-9">16</div>
          <div className="dev-css-mq-5-4">5</div>
          <div className="dev-css-mq-3-2">3</div>
          <div className="dev-css-mq-1-1">1</div>
        </div>
      </div>

      <button onClick={() => setShow(o => !o)}>[_]</button>
    </div>, document.body);
  }
  return null;
}
