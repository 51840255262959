import { memo, useContext } from 'react';
import { Redirect, useLocation } from "react-router";
import { UnitContext } from '../utils/providers/UnitProvider';
// export const UnitToIndexMap = [4, 8, 10, 12, 36, 42, 30];
export const TextMap = ['史前', '秦漢', '三國', '隋唐', '宋元', '元朝', '明代'];
export const TitleMap = ['蘇秦', '造紙', '武備', '武后', '邊疆', '元朝', '傳教'];

function CompatUrlRedirect() {
  const location = useLocation();
  const { units } = useContext(UnitContext);
  console.log('CompatUrlRedirect', location.pathname);
  if ('/main.php' === location.pathname) {
    return <Redirect to={`/`}></Redirect>;
  } else if ('/subui.php' === location.pathname) {
    let id = parseInt((new URLSearchParams(location.search)).get('id') as string);
    let islandIndex = 1;

    let unit = units.find(x => x.lv1id === id);
    if (units.length > 0 && undefined !== unit) {
      // console.log(units);
      islandIndex = unit.imageIndex;
    } else {
      // console.log('id');
      islandIndex = [4, 8, 10, 12, 60, 36, 42, 30, 66, 54, 48].findIndex(x => x === id);
    }

    islandIndex = -1 === islandIndex ? 1 : (islandIndex);
    console.log('CompatUrlRedirect', location.pathname, id, islandIndex, `/island/${islandIndex}/main`);
    // return <></>;
    return <Redirect to={`/island/${islandIndex}/main`}></Redirect>;
  }

  return <Redirect to="/"></Redirect>;
}

export default memo(CompatUrlRedirect);
