import React, { useEffect, useState } from 'react';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
// import { getPassedUnitCount } from '../../pages/arena';
import { getHeader, getMyUnitsResult, getUserRankingScore, putUserSettings } from '../../utils/api';
import ModelHeader from '../../utils/models/joined/Header';
// import MyArenaResult from '../../utils/models/joined/MyArenaResult';
import MyUnitResult from '../../utils/models/joined/MyUnitResult';
import { useUnitContext } from '../../utils/providers/UnitProvider';
import { getLevelBySumScore } from '../constants';
import ModalProfileAvatorSelector from './ModalProfileAvatorSelectorV4';
import "./ModalProfileV4.scss";
import ModalReel from './ModalReel';

export const LevelTextMap = ['書生', '秀生', '舉人', '貢士', '進士', '探花', '探花', '榜眼', '榜眼', '狀元', '狀元', '狀元',];

interface ModalProfileV4Props {
  onClose?: () => void,
  onAvatorUpdate: (n: number) => void,
}

const ModalProfileV4 = ({ onClose, onAvatorUpdate }: ModalProfileV4Props) => {
  const [userInfo, setUserInfo] = useState<{
    userInfo: null | ModelHeader,
    unitMarks: Array<MyUnitResult>,
    unitMarksByUnitId: { [unidId: number]: MyUnitResult },
    // myArenaResult: MyArenaResult,
    totalMarks: number,
    level: string,
  }>({
    userInfo: null,
    unitMarks: [],
    unitMarksByUnitId: {},
    // myArenaResult: {
    //   countUnit: {},
    //   unitTopest: {},
    //   questionCountByUnit: {},
    //   correctQuestions: {},
    //   arenas: [],
    // },
    totalMarks: 0,
    level: '',
  });
  const { units } = useUnitContext();

  useEffect(() => {
    Promise.all([
      getHeader(),
      getMyUnitsResult(),
      getUserRankingScore(),
      // getMyArenaResult(),
    ]).then(([
      userInfo, unitMarks,
      userRankingScore,
      // myArenaResult
    ]) => {
      console.log(userRankingScore);
      let totalMarks = 0;
      if (userRankingScore.length > 0) {
        totalMarks = parseInt(userRankingScore[0].sum_score);
      }
      // console.log(unitMarks);
      setUserInfo({
        userInfo, unitMarks, unitMarksByUnitId: Object.fromEntries(unitMarks.map(x => [x.unit_id, x])),
        // totalMarks: unitMarks.reduce((p, c) => p + parseInt(c.totalScore), 0),
        // myArenaResult
        totalMarks,
        level: getLevelBySumScore(totalMarks),
      });
    });
    // setLevelMarks([1,2,3,4,5]);
  }, []);


  const onProfileIconSelect = (n: number) => {
    console.log('onProfileIconSelect', n);
    putUserSettings('character', n).then(() => { });
    onAvatorUpdate(n);
  }

  if (null === userInfo.userInfo) {
    return (
      <ModalReel className="modal-profile" open={true} onClose={onClose}>

      </ModalReel>
    )
  }
  console.log(userInfo);
  return (
    <ModalReel className="modal-profile" open={true} onClose={onClose}>
      <div className="modal-profile-body">
        <div className="modal-profile-body-info">
          <div className="modal-profile-body-info-avator">
            <ModalProfileAvatorSelector userInfo={userInfo.userInfo} unitMarks={userInfo.unitMarks} handleClick={onProfileIconSelect} />
            {/* <img src={AvatorIcons[userInfo.userInfo.userSettings.character]} tabIndex={1} alt="我的戶口" /> */}
          </div>
          <div className="modal-profile-body-info-name">
            <div className="modal-profile-body-info-name-l"></div>
            <div className="modal-profile-body-info-name-c">
              <span className="modal-profile-body-info-name-name">{userInfo.userInfo.cname}</span>
              <span className="modal-profile-body-info-name-school_name">{userInfo.userInfo.cSchoolName} ({userInfo.userInfo.classNo})</span>
            </div>
            <div className="modal-profile-body-info-name-r"></div>
            <div className="modal-profile-body-info-name-layer">
              <span className="modal-profile-body-info-name-name">{userInfo.userInfo.cname}</span>
              <span className="modal-profile-body-info-name-school_name">{userInfo.userInfo.cSchoolName} ({userInfo.userInfo.classNo})</span>
            </div>
          </div>
        </div>
        <div className="modal-profile-body-score">
          <div className="modal-profile-body-score-stars">
            <div className="modal-profile-body-score-item-l"></div>
            <div className="modal-profile-body-score-item-c">
              <span className="modal-profile-body-score-stars-star"></span>{userInfo.userInfo._.viewUserTotalStars}</div>
            <div className="modal-profile-body-score-item-r"></div>
          </div>
          <div className="modal-profile-body-score-score">
            <div className="modal-profile-body-score-item-l"></div>
            <div className="modal-profile-body-score-item-c">
              <div className="modal-profile-body-score-score-score">{userInfo.totalMarks}</div>
              <div className="modal-profile-body-score-score-title">({userInfo.level})</div>
            </div>
            <div className="modal-profile-body-score-item-r"></div>
          </div>
          <div className="modal-profile-body-score-title">
            <div className="modal-profile-body-score-item-l"></div>
            <div className="modal-profile-body-score-item-c">{userInfo.level}</div>
            <div className="modal-profile-body-score-item-r"></div>
          </div>
        </div>
        <div className="modal-profile-body-units">
          <ScrollbarVertical>
            <div>
              {units.map(({ name, lv1id }, index) => {
                let temp = userInfo.unitMarksByUnitId[lv1id] ?? {
                  unit_name: name,
                  totalStar: 0,
                };
                return <div className="modal-profile-body-units-unit" key={lv1id}>
                  <div className="modal-profile-body-units-unit-title">{temp.unit_name}</div>
                  <div className="modal-profile-body-units-unit-result">
                    <div className="modal-profile-body-units-unit-result-bar">
                      <svg viewBox="0 0 459 26" style={{}} className="modal-profile-body-units-unit-result-bar-wrapper">
                        <line x1={13} y1={13} x2={0 === parseInt(temp.totalStar) ? 13 : (459 - 13) * parseInt(temp.totalStar) / 7} y2={13} data-totalStar={temp.totalStar} />
                      </svg>
                    </div>
                    <div className="modal-profile-body-units-unit-result-percent">{Math.round(100 * parseInt(temp.totalStar) / 7)}%</div>
                    <div className="modal-profile-body-units-unit-result-stars">
                      <div className="modal-profile-body-units-unit-result-stars-star"></div>
                      <div className="modal-profile-body-units-unit-result-stars-total">{temp.totalStar} / 7</div>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </ScrollbarVertical>
        </div>
      </div>
    </ModalReel>)
}

export default ModalProfileV4;
